/* Styles go here */

@font-face {
  font-family: "GAMEBOY";
  src: url(/static/media/GAMEBOY.3ada9815.ttf) format('truetype');
}

body { display: block !important;
  font-family: 'GAMEBOY', monospace;
  background-color: black;
  color: white;
  text-align: center;
  font-size: 1.1em;
 }

 .sunrise {
   background : rgb(255,180,130);
   background : linear-gradient(90deg, rgba(255,180,130,1) 0%, rgba(255,180,255,1) 100%);
   color : rgba(0,0,0,85%);
 }

 .genesis {
   background: black;
   color: white
 }

.token {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  display: inline-inline-block;
}

#sunrise_header {
  letter-spacing: -0.45em;
  font-size: 1.2em;
  color: rgba(120,0,120,1);
}

#genesis_header {
  letter-spacing: -0.45em;
  font-size: 1.2em;
  color: rgba(120,0,120,1);
}

.marker {
  color: white;
  background-color: rgba(120,0,120,1);
  padding-left:10px;
  padding-right:10px;
}

ul {
  list-style-type: none;
  margin-top: 0;
  padding: 0;
}

.cyan {
  color: rgba(0, 255, 255);
}

.magenta {
  color: rgba(255, 0, 255);
}

.yellow {
  color: rgba(255, 255, 0);
}

.green {
  color: rgba(0, 255, 0);
}

.aquarel {
  color: rgb(0, 150, 150)
}

.red {
  color: rgba(255, 0, 0);
}

#sunrise .cyan {
  background: rgba(0, 150, 150);
  color: white;

}

#sunrise .blue {
  background: rgba(0, 0, 150);
  color: white;

}

#sunrise .magenta {
  background: rgba(150, 0, 150);
  color: white;
}

.magenta_sunrise  {
  color: rgba(150, 0, 150);
}

#sunrise .yellow {
  background: rgba(150, 150, 0);
  color: white;
}

#sunrise .green {
  background: rgba(0, 150, 0);
  color: white;
}

#sunrise .red {
  background: rgba(150, 0, 0);
  color: white;
}

#sunrise .black {
  background: rgba(0, 0, 0);
  color: white;
}




span.anim:after {
  content: " ...";
  opacity: 0;
  -webkit-animation: cursor 0.9375s infinite;
          animation: cursor 0.9375s infinite;
}

span.anim:before {
  content: "... ";
  opacity: 0;
  -webkit-animation: cursor 0.9375s infinite;
          animation: cursor 0.9375s infinite;
}

#audio-control {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 4px;
  display: inline-block;
}

#claim_address {
  font-size: 0.8em;
}

@-webkit-keyframes cursor {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes cursor {
  0% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}



#buttonBreak {
  padding: 20px 75px 0 75px;
}

video {
  width: 100%;
  max-width: 400px;
  height: auto !important;
  box-shadow: 5px 5px 10px  rgba(0, 0, 0, 0.5);
}

.vid img {
  width: 100%;
  max-width: 400px;
  height: auto !important;
  box-shadow: 5px 5px 10px  rgba(0, 0, 0, 0.5);
}


.bloom_mag {
  text-shadow: 0px 0px 30px magenta;
}


#sunrise video {
  width: 100%;
  max-width: 400px;
  height: auto !important;
  box-shadow: 5px 5px 10px  rgba(0, 0, 0, 0.5);
}

.pfp {
  width: 100%;
  max-width: 500px;
  height: auto !important;
  box-shadow: 5px 5px 10px  rgba(0, 0, 0, 0.5);
}

.vid {
  padding: 0 !important;
  min-width: 200px;
}

.text-responsive {
  font-size: calc(100% + 3.5vw + 1vh);
}

.l337 {
  color: magenta;
  -webkit-animation: colorchange 2.133s infinite alternate;
}

.l3373 {
  color: yellow;
  -webkit-animation: colorchange2 2.133s infinite alternate;
}

.l3372 {
  background-color: rgb(255,0,255) !important;
  border-color: rgb(255,0,255) !important;
}

.l3372:hover {
  background-color: rgb(200,0,200) !important;
  border-color: rgb(200,0,200) !important;
}

#sunrise .l3372 {
  background-color: rgb(0,122,122) !important;
  border-color: rgb(0,122,122) !important;
}

#sunrise .l3372:hover {
  background-color: rgb(0,180,180) !important;
  border-color: rgb(0,180,180) !important;
}

.flicker {
  color: white;
  -webkit-animation: colorchange3 0.234s infinite alternate;
}

.flicker_magenta {
  color: white;
  -webkit-animation: colorchange4 0.234s infinite alternate;
}

.flicker_cyan {
  color: white;
  -webkit-animation: colorchange5 0.234s infinite alternate;
}

@-webkit-keyframes colorchange {
  0% {
    color: cyan;
  }

  10% {
    color: yellow;
  }

  20% {
    color: lightgreen;
  }

  30% {
    color: magenta;
  }

  40% {
    color: cyan;
  }

  50% {
    color: yellow;
  }

  60% {
    color: lightgreen;
  }

  70% {
    color: magenta;
  }

  80% {
    color: cyan;
  }

  90% {
    color: yellow;
  }

  100% {
    color: magenta;
  }
}

@-webkit-keyframes colorchange2 {

  0% {
    color: yellow;
  }

  10% {
    color: lightgreen;
  }

  20% {
    color: magenta;
  }

  30% {
    color: cyan;
  }

  40% {
    color: yellow;
  }

  50% {
    color: lightgreen;
  }

  60% {
    color: magenta;
  }

  70% {
    color: cyan;
  }

  80% {
    color: yellow;
  }
  90% {
    color: magenta;
  }

  100% {
    color: cyan;
  }
}

@-webkit-keyframes colorchange3 {

  0% {
    color: white;
  }

  60% {
    color: white;
  }

  100% {
    color: black;
  }

}

@-webkit-keyframes colorchange4 {

  0% {
    color: rgb(150,0,150);
  }

  60% {
    color: white;
  }

  100% {
    color: white ;
  }

}

@-webkit-keyframes colorchange5 {

  0% {
    color: rgb(0,150,150);
  }

  60% {
    color: white;
  }

  100% {
    color: white ;
  }

}

.rarity {
  border-color: magenta !important;
}

.opensea {
  background: white;
}

.black_opensea, .black_opensea:hover {
  color: black !important;
  font-weight: bold;
  font-size: 1.2em;
  text-decoration: none;
}

.footer {
  display: flex;
  width: 20%;
  height: 50px;
  margin-top: 100px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.footer img {
  height: 30px;
}

.img_link {
  fill: white;
  margin-right: 20px;
  margin-left: 20px;
  padding: 5px;
  transition: opacity 200ms ease;
}

.img_link:hover {
  -webkit-filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(180deg) saturate(100000%);
          filter: brightness(0.5) sepia(1) saturate(10000%) hue-rotate(180deg) saturate(100000%);
}

.filter-white {
  -webkit-filter: invert(85%) sepia(100%) saturate(28%) hue-rotate(39deg) brightness(106%) contrast(110%);
          filter: invert(85%) sepia(100%) saturate(28%) hue-rotate(39deg) brightness(106%) contrast(110%);
}

.black-bg {
  background-color: #000000;
}

.meta-gray {
    -webkit-filter: grayscale(1);
}
.meta-normal {
    -webkit-filter: grayscale(0);
}

.forbidden-div:hover {
    cursor: not-allowed;

}

.forbidden-button {
    pointer-events: none;
}

.smallPrint {
  font-size: 0.8em;
  color: rgba(0,0,0,0.2);
}

.small {
  font-size: 0.7em;
}

.maincontent {
  height: calc(100vh + 1px);
}

.click {
  font-size: 0.75em;
  color: rgb(70,70,70);
}

#sunrise .click {
  font-size: 0.75em;
  color: rgba(0,0,0,20%);
}

.l337bg {
  -webkit-animation: colorchange_bg 1.875s infinite;
}

.l337intense {
  -webkit-animation: colorchange 2.133s infinite alternate;
}

@-webkit-keyframes colorchange_bg {
  0% {
    background: magenta;
    color: blue;
  }

  20% {
    background: magenta;
    color: blue;
  }

  25% {
    background: cyan;
    color: red;
  }

  45% {
    background: cyan;
    color: red;
  }

  50% {
    background: red;
    color: green;
  }

  70% {
    background: red;
    color: green;
  }

  75% {
    background: lightgreen;
    color: magenta;
  }

  95% {
    background: lightgreen;
    color: magenta;
  }

  100% {
    background: magenta;
    color: blue;
  }
}

.card {
  background: black;
}

hr {
  border: 10px rounded;
  border-color: white;
}

#sunrise hr {
  border: 10px rounded;
  border-color: transparent;
}

.dancing_bots_12 {
  position: absolute;
  bottom: 0;
  left: calc(50% - 400px);
}

.dancing_bots_4 {
  position: absolute;
  bottom: 0;
  left: calc(50% - 200px);
}

.dancing_bots_12 img {
  height: 80px;
}

.metadata {
  font-size: 0.8em;
}

input[disabled=disabled], input:disabled {
  pointer-events: none;
}
.rotate90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.invert {
  -webkit-filter: invert(100%);
          filter: invert(100%)
}

.today {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}

.inprogress {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(0,0,0,75%);
  color: white;
  -webkit-animation: blinker 0.9375s linear infinite;
          animation: blinker 0.9375s linear infinite;
}

.faq {
    cursor: pointer;
    background: transparent;
}

@-webkit-keyframes blinker {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
}

@keyframes blinker {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
}

#boonknavbar {
  background: transparent;
}

@media only screen and (max-width: 859px) {
  #boonknavbar {
    display: none
  }
  .hide-mobile {
    display: none
  }
  .vertical_roadmap {
    display: show;
  }
  .horizontal_roadmap {
    display: none;
  }
  .dancing_bots_12 {
    display: none;
  }
  .backtohome {
    display: show
  }
}

@media only screen and (min-width: 860px) {
  .vertical_roadmap {
    display: none;
  }
  .horizontal_roadmap {
    display: show;
  }
  .dancing_bots_4 {
    display: none;
  }
  .backtohome {
    display: none
  }
}

@media only screen and (max-height: 800px) {
  .dancing_bots_12 {
    display: none;
  }
  .dancing_bots_4 {
    display: none;
  }
}

@media (min-width: 768px) {
.navbar-brand.abs
    {
        position: absolute;
        width: auto;
        left: 100%;
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
        text-align: center;
    }
}

